/*-----------------------------------------------------------------------------------
  Template Name: GreenPark
-----------------------------------------------------------------------------------
  
  CSS INDEX
  ===================


	01. Вспомогательные классы
	02. Типография
	03. Header
	04. Footer
	05. Херо
	06. Карточка товара
	07. Карточка Новости
	08. Хлебные крошки
	09. Отзыв
	10. Форма
	11. Табы
	12. Фильтры
	13. Схема работы
	14. Галерея


-----------------------------------------------------------------------------------*/




$border-color: #ccc;



.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}





@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}





$main_font: 'Muller';

@import 'vars';
 @import 'bootstrap';
 @import 'bootstrap-compass';
 /*@import 'awesome/font-awesome';*/
 @import 'xss';
  @import 'default';
  


  /* General styles
 **************************************************/
body {
	font-size: 16px;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	background: #f3f3f3;
}

a,a:hover,a:focus {
	text-decoration: none;
	outline: 0;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
	font-family: "Lato", Helvetica, Arial, sans-serif;
	font-weight: 300;
}
.fac-title{
	border-bottom: 1px solid #ccc;
	margin: 0 0 30px 0;
	padding: 20px 0;
}

h3.fac-title{
	font-size: 26px;
}
h2.fac-title{
	font-size: 30px;
}

.fac-big-title{
	font-size: 50px;
	margin-bottom: 25px;
}

input[type=text],
input[type=email],
input[type=password],
textarea{
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #555555;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
textarea {
  height: auto;
}
input[type=submit]{
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.428571429;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: 4px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	color: #333333;
	background-color: #ffffff;
	border-color: #cccccc;
}
input[type=submit]:hover{
	color: #333333;
	background-color: #ebebeb;
	border-color: #adadad;
}
/* mobile menu
 **************************************************/
.mobilemenu {
	position: fixed;
	top: 5px;
	left: 5px;
	right: 0;
	z-index: 1002;
	font-size: 30px;
	padding: 2px 10px;
	background: #2b2b2b;
	display: block;
	width: 50px;
	height: 50px;
	color: #fff;
	display: none;
	text-align: center;
}

.mobilemenu:hover,.mobilemenu:focus {
	color: #fff;
}


/* layout
 **************************************************/
#sidebar{
	position: fixed;
	width:250px;
	background: #2b2b2b;
	top: 0;
	left: 0;
	bottom: 0;
}

#sidebar.has-bg{
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

#sidebar.has-bg:before{
	content:'';
	position:absolute;
	top:0;
	left:0;
	bottom:0;
	right:0;
	background-color: #000;
	opacity: 0.8;
}

#sidebar.has-bg ul#navigation > li{
	background-color: rgba(48, 48, 48, 0.6) !important;
	border-top: 1px solid rgba(55, 55, 55, 0.4) !important;
 	border-bottom: 1px solid rgba(43, 43, 43, 0.4) !important;
}

#sidebar.has-bg ul#navigation > li:hover,
#sidebar.has-bg ul#navigation > li:focus, 
#sidebar.has-bg ul#navigation > li.current-menu-item, 
#sidebar.has-bg ul#navigation > li.current-menu-parent{
	background-color: rgba(54, 54, 54, 0.1) !important;
}

#sidebar-wrapper{
	position: relative;
	height: 100%;
	width: 100%;
	overflow-y: auto;
	// -webkit-overflow-scrolling: touch;
}

#sidebar-inner{
  position: relative;
  min-height: 100%;
}

#main-nav {
	position: relative;
	text-align: left;
	width: 100%;
	padding-bottom: 0;

}

#main {
	position: absolute;
	left: 250px;
	right: 0;
	top: 0;
	bottom: 0;
	background: #fefefe;
	// overflow: hidden;
	// -webkit-overflow-scrolling: touch;
}

.fac-page {
	// overflow:hidden;
	position: absolute;
	top: 0;
	left: 2000px;
	bottom: 0;
	width: 85%;
	background-color: #f7f7f7;
	-webkit-overflow-scrolling: touch;
}

.home {
	// overflow:hidden;
	position: absolute;
	top: 0;
	left: 0px;
	bottom: 0;
	z-index: 0;
	width: 100%;
	background-color: #f3f3f3;
}

#overlay {
	position: absolute;
	top: 0;
	left: 0px;
	bottom: 0;
	right: 0px;
	background-color: #000;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
	filter: alpha(opacity=25);
	opacity: 0.25;
	display: none;
	z-index: 300;
	cursor: crosshair;
	// cursor: url(../img/pointer.png), auto;
}
.loading{
	width: 35px;
	height: 35px;
	position: absolute;
	top: 50%;
	left:50%;
	// background-image: url(../img/loading.gif);
	background-repeat: no-repeat;
}
#runner {
	position: absolute;
	width: 30px;
	height: 20px;
	color: #fff;
	font-size: 20px;
	z-index: 2000;
}

.currentpage {
	-webkit-box-shadow: -2px 0 4px -2px rgba(0,0,0,.25);
	box-shadow: -2px 0 4px -2px rgba(0,0,0,.25);
}


/* sidebar
 **************************************************/
#nav-container {
	position: relative;
	padding-bottom: 70px;
}

#profile {
	text-align: center;
	padding: 30px 10px;
	position: relative;
}

#profile .portrate {
	width: 125px;
	margin: 0 auto;
	height: 125px;
}

#profile .portrate img{
	width: 100%;
	height: 100%;
	border-radius: 100px;
	-webkit-border-radius: 100px;
    -moz-border-radius: 100px;

}
#profile .title h2 {
	font-size: 22px;
	font-weight: 300;
	color: #fff;
	margin: 20px 0 10px 0;
}

#profile .title h3 {
	font-size: 18px;
	font-weight: 300;
	color: #aaa;
	margin: 0px 0 10px 0;
}

ul#navigation {
	padding: 0;
	margin: 0;
}

ul#navigation > li {
	display: block;
	float: none;
	clear: both;
	background: #303030;
	border-top: 1px solid #373737;
	border-bottom: 1px solid #2B2B2B;
	cursor: pointer;
	-webkit-transition: background 0.5s;
	-moz-transition: background 0.5s;
	-o-transition: background 0.5s;
	-ms-transition: background 0.5s;
	transition: background 0.5s;
}

ul#navigation > li > a {
	color: #ccc;
	font-size: 14px;
	line-height: 45px;
	margin-left: 15px;
	display: block;
}

ul#navigation > li:hover,
ul#navigation > li:focus {
	background: #363636;
}

ul#navigation > li:hover > a,
ul#navigation > li:focus > a {
	color: #f7f7f7;
}

ul#navigation > li.current-menu-item,
ul#navigation > li.current-menu-parent {
	font-weight: bold;
}

ul#navigation > li.current-menu-item > a,
ul#navigation > li.current-menu-parent > a{
	color: #fff;
}

ul#navigation > li.external:hover > a .fa, 
ul#navigation > li.current-menu-item > a .fa,
ul#navigation > li.current-menu-parent > a .fa{
	color: #03cc85;
}

ul#navigation > li.current-menu-item,
ul#navigation > li.current-menu-parent
{
	background: #363636;
	border-top: 1px solid #3d3d3d;
	border-bottom: 1px solid #252525;
}


ul#navigation > li > a > .fontawesome-text {
	padding-left: 5%;
	margin-left: -15px;
	line-height: 45px;
	display: inline-block;
	-webkit-box-shadow: -3px 0 5px -4px #000;
	box-shadow: -3px 0 5px -4px #000;
}

ul#navigation > li > a > .fa {
	float: left;
	line-height: 45px;
	width: 50px;
	text-align: center;
	font-size: 27px;
	position: relative;
	left: -15px;
}

ul#navigation .sub-menu{
	margin:0;
	padding:0;
	background-color: #2b2b2b;
	display: none;
	list-style: none;
}

ul#navigation .sub-menu > li {
	padding-left:20px;
	margin: 0;
}

ul#navigation .sub-menu > li a{
	display: block;
	font-size: 12px;
	color: #8E8E8E;
	line-height: 40px;
	-webkit-transition: all .25s ease;
	   -moz-transition: all .25s ease;
	    -ms-transition: all .25s ease;
	     -o-transition: all .25s ease;
	        transition: all .25s ease;
}

ul#navigation .sub-menu > li a:hover,
ul#navigation .sub-menu > li a:focus,
ul#navigation .current-menu-parent li.current-menu-item a{
	color: #dbdbdb;
	margin-left:5px;
}

ul#navigation .sub-menu .fa{
	padding:0 8px 0 0;
}

ul#navigation .menu-item-has-children{
	position: relative;
}

/*ul#navigation .menu-item-has-children:after{
	content:"+";
	position: absolute;
	top:12px;
	right:20px;
	color:#454545;
}*/
/*ul#navigation .menu-item-has-children:hover:after{
	content:"-";
}*/

#navigation .submenu-trigger{
	position: absolute;
	top:0;
	right:0;
	width:50px;
	height: 45px;
	line-height: 45px;
	text-align: center;
	font-size: 20px;
    color: #b7b7b7;
}

#navigation .sub-menu .submenu-trigger{
	font-size: 16px;
	height: 40px;
	line-height: 40px;
}


#navigation .menu-item-has-children .submenu-trigger:after{
	content:"+";
	display: block;
}
#navigation .menu-item-has-children.active > .submenu-trigger:after{
	content:"вЂ“";
}


body.fac-touch-device ul#navigation .menu-item-has-children> a{
	margin-right: 50px;
}

#sidebar-footer{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: #202020;
	border-top: 1px solid #1A1A1A;
	text-align: center;
  	overflow: hidden;
}

#sidebar-footer #lang_sel_list{
	text-align: center;
}
#sidebar-footer #lang_sel_list li{
	float: none;
	display: inline-block;
}
#sidebar-footer #lang_sel_list li a{
	background: transparent;
}
.social-icons ul {
	padding: 0 20px;
	margin: 0;
	list-style: none;
	text-align: center;
}

.social-icons li {
	/* float: left; */
	/* width: 33%; */
	padding: 5px;
	text-align: center;
	display: inline-block;
}

.social-icons a {
	color: #ccc;
}

.social-icons a:hover,.social-icons a:focus {
	color: #fff;
}

.social-icons i {
	font-size: 16px;
}

i.academia,
i.researchgate{
	background-repeat: no-repeat;
	background-position: top;
	width: 18px;
	height: 15px;
	display: block;
	position: relative;
	top: 8px;
	margin: 0 auto;
}
i.academia{
	background: url(../img/i-academia.png);
}
i.researchgate{
	background: url(../img/i-researchgate.png);
}

.social-icons a:hover i.academia,
.social-icons a:hover i.researchgate{
	background-position: bottom;
}

#copyright{
	text-align:center;
	font-size:0.7em;
	color: #8E8E8E;
	margin-bottom: 5px;
}
/* page specifics
 **************************************************/
.page-container {
	position: relative;
}

.pageheader {
	background: #f3f3f3;
	position: relative;
	padding: 60px 0 45px 0;
}
.pageheader.has-bg{
	padding: 190px 0 35px 0;
	background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.pageheader.has-bg:after{
	content: none !important;
}

.pageheader.has-bg .headercontent .title{
	color:#fff;
	border: none;
}

.pageheader:after {
	top: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(8, 4, 13, 0);
	border-top-color: #f3f3f3;
	border-width: 30px;
	left: 50%;
	margin-left: -30px;
	z-index: 1;
}

.headercontent .title {
	font-size: 50px;
	margin-bottom: 25px;
	border-bottom: 1px solid #ccc;
	padding: 0 0 20px 0;
}
.headercontent .title.noborder{
	border:none;
}
.headercontent .subtitle {
	border-bottom: 1px solid #ccc;
	margin: 0 0 10px 0;
	padding: 5px 0;
}

.headercontent .subtitle h3 {
	margin: 0;
	padding: 0;
}

.fac-page .section{
	padding: 70px 0;
	position: relative;
}
.fac-page #inside >.row {
	padding: 70px 10%;
	position: relative;
	margin: 0;
}
.fac-page #inside >.row:first-child{
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

}
// .fac-page #inside >.row:first-child:before{
// 	top: 100%;
// 	border: solid transparent;
// 	content: " ";
// 	height: 0;
// 	width: 0;
// 	position: absolute;
// 	pointer-events: none;
// 	border-color: rgba(8, 4, 13, 0);
// 	border-top-color: #f3f3f3;
// 	border-width: 30px;
// 	left: 50%;
// 	margin-left: -30px;
// 	z-index: 1;
// }

.fac-page #inside >.row:nth-child(odd){
	background-color: #f7f7f7;
}
.fac-page #inside >.row:first-child{
	background-color: #f3f3f3;	
}
.fac-page #inside >.row:nth-child(even){
	background-color: #fcfcfc;
}
.section-container {
	padding: 0 10%;
}

.color-1 {
	background: #fcfcfc;
}

.color-2 {
	background: #f7f7f7;
}

.color-3 {
	background: #2c2c2d;
	color: #fff;
}

.color-4 {
	background: #000;
}

.section .title {
	border-bottom: 1px solid #ccc;
	margin: 0 0 30px 0;
	padding: 20px 0;
}


.biothumb{position: relative;}
.biothumb .overlay{
	position: absolute;
	width: 100%;
	color:#fff;
	bottom: 0;
	background-color: black;
	background-color: rgba(0,0,0,0.5);
	padding:25px;
}
.biothumb .overlay h1{
	font-size: 45px;
}

.fix-bg{
	background-attachment: fixed !important;
}

.pagecontents.has-sidebar{
	position: relative;
}
.pagecontents.has-sidebar:after{
	content:'';
	position: absolute;
	top:0;
	bottom: 0;
	right:25%;
	width:1px;
	background-color: #ECECEC;
}
.pagecontents.has-sidebar .section{
    padding: 40px 0;
}
.pagecontents.has-sidebar .section-container{
    padding: 0 5%;
}

/*************************************************
 *  uls
 **************************************************/
.ul-bolded li {
	font-weight: bold;
}

.ul-boxed li {
	padding: 7px;
	background-color: #FdFdFd;
	margin-bottom: 10px;
	-webkit-box-shadow: 0px 3px 3px -3px #ccc;
	box-shadow: 0px 3px 3px -3px #ccc;
	border-radius: 2px;
	border-left: 5px solid;
}

/* ul-card
 **************************************************/
ul.ul-card {
	padding: 0;
	margin: 0;
	list-style: none;
}

ul.ul-card li {
	position: relative;
	padding: 50px 15px 20px 15px;
	background-color: #fff;
	margin-bottom: 15px;
	-webkit-box-shadow: 0px 3px 3px -3px #ccc;
	box-shadow: 0px 3px 3px -3px #ccc;
	border-radius: 3px;
	margin-left: 10px;
	color: #333;
}

ul.ul-card li .dy {
	position: absolute;
	top: 10px;
	left: -10px;
	border-radius: 0px;
	padding: 0;
color: white;
}

ul.ul-card li .dy span {
	padding: 7px 10px;
	line-height: 30px;
	margin: 0;
}

/*ul.ul-card li .dy .degree {
	background-color: #03bb7a;
}*/

ul.ul-card li .dy .year {
	/*background-color: #03cc85;*/
	-webkit-box-shadow: -3px 0px 7px -3px rgba(68, 68, 68, 0.5);
	box-shadow: -3px 0px 7px -3px rgba(68, 68, 68, 0.5);
	margin-left: -3px !important;
}

ul.ul-card li .description p {
	font-size: 16px;
	margin: 0;
}

ul.ul-card li .description p.where {
	font-weight: 300;
	font-size: 14px;
}

/* ul-dates
 **************************************************/
ul.ul-dates,ul.ul-dates-gray {
	margin: 0;
	padding: 0;
	list-style: none;
}

ul.ul-dates li,ul.ul-dates-gray li {
	margin-bottom: 15px; /*margin-left:  20px;
	*/
}
ul.ul-dates li:after,
ul.ul-dates-gray li:after{
	content: '';
	display: table;
	clear: both;
}
ul.ul-dates div.dates,ul.ul-dates-gray div.dates {
	float: left;
	width: 75px;
}

ul.ul-dates div.dates span,ul.ul-dates-gray div.dates span {
	/*background-color: #03cc85;*/
	padding: 6px;
	display: block;
	text-align: center;
	color: #fff;
	position: relative;
}

ul.ul-dates-gray div.dates span {
	background-color: #BBBBBB !important;
}

/*ul.ul-dates div.dates span:last-child {
	background-color: #03bb7a;
}*/

ul.ul-dates-gray div.dates span:last-child {
	background-color: #979797 !important;
}

ul.ul-dates-gray div.dates span:first-child,
ul.ul-dates div.dates span:first-child {
	-webkit-box-shadow: 0px 2px 6px -3px rgba(68, 68, 68, 0.5);
	box-shadow: 0px 2px 6px -3px rgba(68, 68, 68, 0.5);
	z-index: 3;
	position: relative;
}

ul.ul-dates div.content,ul.ul-dates-gray div.content {
	margin-left: 90px;
	min-height: 70px;
	padding-top: 10px;
}

ul.ul-dates div.content h4,ul.ul-dates-gray div.content h4 {
	font-weight: 400;
	font-size: 16px;
	margin-top: 0;
}

ul.ul-dates div.content p,ul.ul-dates-gray div.content p {
	font-size: 13px;
}



/* ul-timeline
 **************************************************/
ul.timeline {
	border-left: 8px solid #FFF;
	list-style: none;
	padding: 1px 0;
	margin-left: 100px;
}

ul.timeline li .date {
	float: left;
	padding: 10px 15px 10px 10px;
	margin-top: 10px;
	margin-left: -115px;
	font-size: 15px;
	width: 110px;
	text-align: center;
	/*background: #03cc85;*/
	color: #fff;
}

/*ul.timeline li.open .data {
	background: #03bb7a;
}*/

ul.timeline li .circle {
	float: left;
	margin: 20px 0;
	margin-left: -14px;
	width: 20px;
	height: 20px;
	border: 4px solid #FFFFFF;
	border-radius: 10px;
	background-color: #d5d5d5;
}

ul.timeline li.open .circle {
	/*background-color: #03bb7a;*/
	-webkit-box-shadow: 0px 2px 6px -3px rgba(68, 68, 68, 0.5);
	box-shadow: 0px 2px 6px -3px rgba(68, 68, 68, 0.5);
}

ul.timeline li .data {
	background: #fdfdfd;
	margin: 10px 0px 10px 20px;
	border-left: 10px solid;
	padding: 18px 20px;
	font-size: 16px;

	-webkit-box-shadow: 0px 3px 3px -3px #ccc;
	box-shadow: 0px 3px 3px -3px #ccc;
	border-radius: 3px;
}

ul.timeline li.open .data {
	/*border-color: #03bb7a;*/
	background: #fff;
}

ul.timeline li .data .subject {
	cursor: pointer;
	color: #777;
	font-size: 18px;
}

ul.timeline li .data .text {
	display: none;
	margin-top: 15px;
	font-size: 14px;
}

/* ul-withdetails
 **************************************************/
.ul-withdetails {
	list-style: none;
	padding: 0;
}

.ul-withdetails li {
	background-color: #FdFdFd;
	margin-bottom: 15px;

	-webkit-box-shadow: 0px 3px 3px -3px #ccc;
	box-shadow: 0px 3px 3px -3px #ccc;
	border-radius: 3px;
	
}
.ul-withdetails li .wrap{
	position: relative;
}
.ul-withdetails li:after{
	content:"";
	display: table;
	clear: both;
}
.ul-withdetails li .image {
	position: relative;
	overflow: hidden;
	float: left;
	width: 160px;
	height: 100%;
	position: absolute;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}


.ul-withdetails li .imageoverlay {
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	/*background: #03cc85;*/
	cursor: pointer;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
	filter: alpha(opacity=70);
	opacity: 0.7;

	-webkit-transition: all 0.3s ease-out;
	   -moz-transition: all 0.3s ease-out;
	    -ms-transition: all 0.3s ease-out;
	     -o-transition: all 0.3s ease-out;
	        transition: all 0.3s ease-out;
}

.ul-withdetails li:hover .imageoverlay{
	left: 0;
}

.ul-withdetails li .imageoverlay i {
	color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    transform: translate3d(-50%,-50%,0);
}

.ul-withdetails li .meta {
	overflow: hidden;
	padding: 30px;
	cursor: pointer;
	margin-left: 160px;
}
.ul-withdetails li .meta h3 {
	margin-top:0;
}
.ul-withdetails li .meta p {
	margin-bottom: 0;
}
.ul-withdetails li.no-details .meta{cursor: inherit;}
.ul-withdetails li.no-details .imageoverlay{display: none;}
.ul-withdetails li .details {
	display: none;
	padding: 20px;
	background: #fff;

	-webkit-box-shadow: inset 0px 3px 5px -3px #ccc;
	box-shadow: inset 0px 3px 5px -3px #ccc;
}
/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
	.ul-withdetails li .image {
		width: 60px;
	}
	.ul-withdetails li .meta {
	    padding: 10px;
	    margin-left: 60px;
	}
	.ul-withdetails li .meta h3 {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 5px;
	}
	.ul-withdetails li .meta p {
		font-size: 13px;
	}
}
/*************************************************
 * lab partnerts
 **************************************************/
#labp-heads-wrap,
.labp-heads-wrap {
	/*background-color: #03bb7a;*/
	position: relative;
	height: 180px; 
}

#labp-heads-wrap:after,
.labp-heads-wrap:after {
	top: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(247, 103, 103, 0);
	/*border-top-color: #03bb7a;*/
	border-width: 15px;
	left: 50%;
	margin-left: -15px;
}

#lab-carousel,
.lab-carousel {
	width: 100%;
	height: 200px;
	position: absolute;
	top: 0;
	left: 0;
}

#lab-carousel div,
.lab-carousel div{
	float: left;
	width: 100px;
	height: 100px;
}

#lab-carousel img,
.lab-carousel img {
	border: none;
	position: relative;

	-webkit-box-shadow: 0 0 10px #000;
	box-shadow: 0 0 10px #000;
}

.caroufredsel_wrapper {
	margin-top: -10px !important;
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

#labp-heads-wrap .next,
#labp-heads-wrap .prev,
.labp-heads-wrap .next,
.labp-heads-wrap .prev {
	position: absolute;
	top: 0;
	height: 100%;
	line-height: 180px;
	padding: 0 10px;
	color: #f7f7f7;
	font-size: 30px;
	z-index: 4;
}

#labp-heads-wrap .next,
.labp-heads-wrap .next {
	right: 0;
}

#labp-heads-wrap .prev,
.labp-heads-wrap .prev {
	left: 0;
}

#labp-heads-wrap .prev.disabled,
#labp-heads-wrap .next.disabled,
.labp-heads-wrap .prev.disabled,
.labp-heads-wrap .next.disabled {
	color: #ccc;
	cursor: default;
}

#lab-details,
.lab-details {
	background-color: #fff;
	padding: 20px;
	padding-top: 40px;
	text-align: center;
	min-height: 150px;

	-webkit-box-shadow: 0px 3px 3px -3px #ccc;
	box-shadow: 0px 3px 3px -3px #ccc;
	border-radius: 2px;
}

#lab-details div,
.lab-details div {
	display: none;
}

#lab-details h3,
.lab-details h3 {
	margin-top: 0;
}
.lab-item-desc{
	margin-top:20px;
	margin-bottom: 20px;
	display: block;
}

/* filter dropdowns
 **************************************************/
#filters,.filters {
	padding: 20px 0 30px 0 !important;
}
#pub-grid{
	padding: 50px 0 30px 0 !important;
}
#filters h3,.filters h3,div#sort {
	margin-top: 30px;
}

#sort span {
	line-height: 30px;
}

.stuck {
	position: fixed !important;
	z-index: 1001;
	top: 0;
	right: 0;

	-webkit-box-shadow: 0px 2px 4px 0px #cecece;
	box-shadow: 0px 2px 4px 0px #cecece;
}

#cd-dropdown{
	display: none;
}
.cd-dropdown,.cd-select {
	position: relative;
	margin: 20px auto;
	display: block;
}

.cd-dropdown > span {
	width: 100%;
	height: 40px;
	line-height: 40px;
	color: #999;
	font-weight: 700;
	font-size: 16px;
	background: #fff;
	display: block;
	padding: 0 10px 0 30px;
	position: relative;
	cursor: pointer;
}

.cd-dropdown > span:after {
	content: '';
	position: absolute;
	right: 0px;
	top: 0px;
	width: 50px;
	text-align: center;
	font-size: 12px;
	padding: 8px;
	height: 70%;
	line-height: 24px;
}

.cd-dropdown.cd-active > span:after {
	content: '';
}

.cd-dropdown ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: block;
	position: relative;
}

.cd-dropdown > span,.cd-dropdown ul li:nth-last-child(-n+3) span {
	-webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.cd-dropdown ul {
	position: absolute;
	top: 0px;
	width: 100%;
}

.cd-dropdown ul li {
	position: absolute;
	width: 100%;
}

/*.cd-active.cd-dropdown > span {
	color: #03cc85;
}*/

.cd-active.cd-dropdown > span,.cd-active.cd-dropdown ul li span {
	-webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.cd-active.cd-dropdown ul li span {
	-webkit-transition: all 0.2s linear 0s;
	-moz-transition: all 0.2s linear 0s;
	-o-transition: all 0.2s linear 0s;
	-ms-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
}

.cd-active.cd-dropdown ul li span:hover {
	/*background: #03cc85;*/
	color: #fff;
}

.cd-dropdown ul li span {
	width: 100%;
	background: #fafafa;
	line-height: 40px;
	padding: 0 10px 0 30px;
	display: block;
	color: #bcbcbc;
	cursor: pointer;
	font-weight: 700;
}

.cd-dropdown a{
	color: #bcbcbc;
}

#miu-filter{
	margin-top: 30px;
}
#miu-filter span {margin-bottom:5px;}

/* publications
 **************************************************/
#pub-grid .mix {
	display: none;
}
#pub-grid .is-paginated .mix{
	display: block;
}
.pitems .item {
	margin-bottom: 20px;
	width: 100%;
	font: inherit;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	-webkit-font-smoothing : inherit;
}

.pitems .pubmain {
	background: #f5f5f5;
	padding: 25px 25px 35px 25px;
	min-height: 130px;
	position: relative;

	-webkit-box-shadow: 0px 3px 3px -3px #ccc;
	box-shadow: 0px 3px 3px -3px #ccc;
	cursor: pointer;
}
.pitems .pubmain .pubcontents{
	padding-right: 135px;
	margin-bottom: 10px;
}
.pitems .pubmain .pubcontents .label{
	display: inline-block;
	margin-right: 8px;
	margin-bottom: 8px;
	padding: 5px 10px;
	font-size: 12px;
}
.pitems .compact{
	min-height: 100px;
}
.pitems .pubtitle {
	margin: 0;
	padding: 0 0 15px 0;
	font-size: 18px;
	font-weight: 400;
}
.pitems .pubtitle a{
	color:#333;

}
.pitems .pubtitle a:hover,
.pitems .pubtitle a:focus{
	color:#666;
}
.pubmain.compact .pubtitle{
	font-size: 16px;
}


.pitems .pubmain.pub-has-thumbnail .pub-thumb{
	float:left;
}
.pitems .pubmain.pub-has-thumbnail .pub-thumb img{
	width: 120px;
	height: auto;
}
.pitems .pubmain.pub-has-thumbnail .pub-contents{  margin-left: 150px;}
.pitems .pubmain.pub-has-thumbnail.compact .pub-contents{ margin-left: 100px;}
.pitems .pubmain div {
	font-size: 12px;
	line-height: 18px;
}

.pitems .pubmain .label {
	margin-right: 10px;
}

.pitems .pubmain .pubassets {
	position: absolute;
	bottom: 0;
	right: 0;
	background: #fafafa;
	text-align: center;
	border-top: 2px solid;
}

.pitems .pubmain .pubassets a {
	color: #9b9b9b;
	padding: 5px 15px;
	float: right;
	font-size: 18px;
}

.pitems .pubmain .pubassets a:hover,.pitems .pubmain .pubassets a:focus,.pitems .pubmain .pubassets a.pubcollapse {
	/*background: #03cc85;*/
	color: #fff;
}

.pitems .pubdetails {
	background: #fff;
	padding: 25px;
	display: none;
}

.pitems .pubmeta{
	font-size: 14px;
	line-height: 18px;
}

.pitems .pubmeta>span.label{
	margin-bottom: 7px;
    display: inline-block;
    font-size: 12px;
    padding: 5px 10px;
}

.pitems .pubmain .pubauthor .pubyear{
	margin:5px 0;
}

#miu-filter .btn{
	margin-bottom: 8px;
	margin-right: 8px;
}
/* Gallery
 **************************************************/
#gallery-header {
	color: #f7f7f7;
}
#gallery-header:after {
	content:none !important;
}

#gallery-header h2 {
	font-size: 50px;
	text-align: center;
	border:none;
	color: #f7f7f7;
}
#gallery-large {
	background: #303030;
	padding-top: 20px;
}

ul.grid {
	max-width: 69em;
	list-style: none;
	margin: 30px auto;
	padding: 0;
}

ul.grid li {
	display: block;
	float: left;
	padding: 15px;
	width: 33%;
}

@media screen and (max-width: 900px) {
	ul.grid li {
		width: 50%;
	}

}

@media screen and (max-width: 400px) {
	ul.grid li {
		width: 100%;
	}

}

ul.grid li >div {
	-webkit-box-shadow: 0 0 8px -1px #000;
	box-shadow: 0 0 8px -1px #000;
	overflow: hidden;
	position: relative;
}

ul.grid li .over {
	position: absolute;
	bottom: -100%;
	width: 102%;
	right: -1%;
	left: -1%;
	color: #fff;
}

ul.grid li .comein {
	position: relative;
	z-index: 1;
	padding: 20px;
	height: 100%;
}

ul.grid li .comein i {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    left: 50%;
    margin-left: -25px;
    font-size: 20px;
    color: #c3bfbf;
    border: 1px solid rgb(132, 132, 132);
    text-align: center;
    padding-top: 0;
    border-radius: 50%;
    line-height: 50px;
    background-color: rgba(167, 167, 167, 0.15);

}
ul.grid li .comein .item-title{
	font-size: 20px;
	margin-top:0;
	color: #e2e2e2;
}
ul.grid li .comein .item-description{
	font-size: 14px;
	color: #a5a5a5;
}

ul.grid li .comein-bg {
	-khtml-opacity: .70;
	-moz-opacity: .70;
	-ms-filter: вЂќalpha(opacity=70)вЂќ;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.7);
	filter: alpha(opacity=70);
	opacity: .70;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: #000;
	z-index: -1;
}

ul.grid li a,ul.grid li img {
	outline: none;
	border: none;
	display: block;
	max-width: 100%;
}

/* at start */
.my-mfp-slide-bottom .mfp-content {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;

	-webkit-transition: all 0.7s ease-out;
	-moz-transition: all 0.7s ease-out;
	-o-transition: all 0.7s ease-out;
	-ms-transition: all 0.7s ease-out;
	transition: all 0.7s ease-out;

	-webkit-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-moz-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-o-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-ms-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .mfp-content {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;

	-webkit-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-moz-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-o-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-ms-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	transform: translateY(0) perspective( 600px ) rotateX( 0 );
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .mfp-content {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;

	-webkit-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-moz-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-o-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-ms-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;

	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	-ms-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	filter: alpha(opacity=80);
	opacity: 0.8;
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
}

/* contanct
 **************************************************/
.contact-office {
	background: #fff url('../img/contact-office.jpg') no-repeat;
}

.contact-lab {
	background: #000 url('../img/contact-lab.jpg') no-repeat;
	color: #fff;
}

/*.contact-office .icon-huge, .contact-lab .icon-huge{
	color: #03cc85;
}*/


.icon-huge {
	font-size: 170px;
	text-align: center;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
	filter: alpha(opacity=30);
	opacity: 0.3;
	display: block;
	padding-top: 30px;
}

@media (max-width: 600px) {
	ul.timeline {
		margin: 0;
		border-left: none;
	}

	ul.timeline .circle {
		display: none;
	}

	ul.timeline .data {
		margin: 0 !important;
		margin-bottom: 10px !important;
		padding-top: 65px !important;
	}

	ul.timeline .date {
		margin-left: 0 !important;
	}

	#sidebar{
		width: 100%;
	}

	#sidebar-wrapper{
		overflow: auto;
	}

	.pitems .pubmain.pub-has-thumbnail .pub-thumb{
		float: none;
		margin-bottom: 20px;
		text-align: center;
	}
	.pitems .pubmain.pub-has-thumbnail .pub-contents{
		margin-left: 0;
	}
	.pitems .pubmain.pub-has-thumbnail .pub-thumb img{
		width: 150px;
	}


}

@media (max-width: 991px) {
	.mobilemenu {
	display: block;
	}

	.social-icons,#main-nav {
		left: 0px;
		z-index: 0;
	}

	#main {
		left: 0;
	}

}

/* blog
**************************************************/
#blog-content{
	position: absolute;
	width:75%;
	height: 100%;
	background-color: #fdfdfd;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	float:left;
}	
#blog-side{
	position: absolute;
	right: 0;
	width:25%;
	height: 100%;
	background-color: #f8f8f8;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
}

#blog-side .post{
	position: relative;
	padding: 25px 15px;
	background-color: #F8F8F8;
	border-top: 1px solid white;
	border-bottom: 1px solid #C6C6C6;
	cursor: pointer;
	-webkit-transition: all .25s ease;
	   -moz-transition: all .25s ease;
	    -ms-transition: all .25s ease;
	     -o-transition: all .25s ease;
	        transition: all .25s ease;
}
#blog-side .post:first-child{
	border-top:none;
}
#blog-side .post .blog-date{
	font-size: 12px;
	color: #A9A9A9;
}
#blog-side .post h4{
	margin: 0 0 8px 0;
	color:#676767;
	font-weight: 700;
	font-size: 15px;
}
#blog-side .post .blog-excerpt{
	font-size: 13px;
	color: #6F6F6F;
}

#blog-side .post:hover,
#blog-side .active{
	background-color: #ececec;
}
#blog-side .post:hover h4,
#blog-side .active h4{
	color:#404040;
}
#blog-side .blog-thumb{
	float:left;
	min-height: 70px;
}
#blog-side .blog-thumb img{
	width:70px !important;
	height: 70px !important;
}
#blog-side .blog-info{
	margin-left:80px;
}
.page-contents{
	padding:70px 0;
}
.post-meta span{
	padding: 0 10px;
}
.post-meta span:first-child{
	padding-left:0;
}

#ajax-single-post{
	position: relative;
}

#postlist{
	position: relative;
}

#postlist.in-transition .post{
	opacity: 0;
}  
.archive-header{
	padding: 20px;
	background-color: #2b2b2b;
	color: #FFF;
	width: 100%;
}
h3.archive-title{
	font-size: 20px;
}
.archive-contnet{
	overflow: auto;
	overflow-x:hidden;
	-webkit-overflow-scrolling: touch;
	height: 80%;
}
#blog-navigation{
	width: 100%;
	height: 35px;
	background-color: #F3F3F3;
	/*padding: 5px;*/
	text-align: center;
	box-shadow: 0px 1px 5px -3px #222;
	line-height: 35px;
	border-bottom: 1px solid #F1F1F1;
}
#blog-navigation > a,
#blog-navigation  >span{
	padding:0 5px;
}
#blog-content .page-contents,
#blog-content .page-contents p {
	font-size: 18px
}
#hideshow{
	display: block;
	position: absolute;
	top: 30px;
	right: -35px;
	width: 85px;
	height: 40px;
	font-size: 16px;
	color: #fff;
	background-color: #2b2b2b;
	z-index: 100;
	padding: 5px 5px 5px 15px;
	border-radius: 20% 0 0 20%;
	line-height: 30px
}
#hideshow i {
	padding-right:10px;
	color: #FFED52;
}
#hideshow span {
	display: none;
}



/*Comment Output*/

.commentlist .reply {
	position: absolute;
	bottom: 0px;
	right: 0px;
	padding: 5px 10px;
	background-color: #03cc85;
	font-size: 12px;
}
.commentlist .reply a {
	color:#fff;
}

.commentlist .alt {}
.commentlist .odd {}
.commentlist .even {}
.commentlist .thread-alt {}
.commentlist .thread-odd {}
.commentlist .thread-even {}
.commentlist li ul.children .alt {}
.commentlist li ul.children .odd {}
.commentlist li ul.children .even {}


.commentlist .comment-meta {} 
.commentlist .comment-meta a {}
.commentlist .commentmetadata {
	font-size: 12px;
	color: #A9A9A9;
}

.commentlist .parent {}
.commentlist .comment {}
.commentlist .children {
	list-style: none;
	margin: 0;
	padding: 0;
	margin-left: 10%;
}
.commentlist .pingback {}
.commentlist .bypostauthor {}
.commentlist .comment-author {}
.commentlist .comment-author-admin {
	background-color: #fdfdfd;
}

.commentlist {
	list-style: none;
	margin: 40px 0;
	padding: 0;
}
.commentlist li {
	margin: 10px 0;
	padding: 20px;
	border-bottom: 1px solid #ECECEC;
	background-color: #f9f9f9;
	position: relative;
	-webkit-box-shadow: 0px 3px 3px -3px #F2F2F2;
	box-shadow: 0px 3px 3px -5px #ccc;
}
.commentlist li p {
	padding: 10px 0;
	color: #6E6E6E;
}
.commentlist li ul {}
.commentlist li ul.children li {}
.commentlist li ul.children li.alt {}
.commentlist li ul.children li.byuser {}
.commentlist li ul.children li.comment {}
.commentlist li ul.children li.bypostauthor {}
.commentlist li ul.children li.comment-author-admin {}
.comment-body {
	position: relative;
}
#cancel-comment-reply {}
#cancel-comment-reply a {}

#commentform{
	margin-top:15px;
}
#commentform .row{
	margin-bottom: 10px;
}
label {
	font-weight: normal;
}
.comment-avatar{
	float: left;
	width:60px;
	text-align: center;
}
.comment-avatar img{
	border-radius: 50%;
}
.commenttext{
	margin-left: 80px;
	padding-top: 7px;
	min-height: 75px;
}
.commenttext cite.fn {
	font-size: 18px;
	font-weight: 700;
}
.comment-reply-title{
	border-bottom: 1px solid #ccc;
	margin: 0 0 30px 0;
	padding: 20px 0;
}

#archive-content{
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
}

/* wordpress defaults
**************************************************/
.sticky{}

.gallery-caption{}
.alignright{
	float:right;
}
.alignleft{
	float:left;
}
.aligncenter{
	text-align: center;
}

img.aligncenter{
	margin:0 auto;
	display: block;
}

.wp-caption a{display: block;}
.wp-caption, .wp-caption img {max-width: 100%; height: auto;}

#blog-content .page-contents p.wp-caption-text{font-size: 0.8em; color:#949494;}

.widget-side{
	margin-bottom: 40px;
}
.widget-side .title{
	padding-bottom: 10px;
	font-size:22px;
}
.widget-side .widget-contents{
	margin-right:30px;
}
.widgettitle{
	border-bottom: 1px solid #ccc;
	margin: 0 0 30px 0;
	padding: 20px 0;
}
.widget ul{
	margin:0;
	padding:0;
	list-style: none;
}
.widget ul li{
	line-height: 1;
	padding: 12px;
	background-color: #FdFdFd;
	margin-bottom: 10px;
	-webkit-box-shadow: 0px 3px 3px -3px #ccc;
	box-shadow: 0px 3px 3px -3px #ccc;
	border-radius: 2px;
	border-left: 5px solid;
}
.widget ul li a {
	color:#666;
}
.widget ul li a:hover{
	text-decoration: underline;
}
.widget ul li:hover{
	background-color: #fff;
}

/* accordion
**************************************************/
.wpb_accordion_header:focus{
	outline: none !important;
}
.wpb_accordion_header{
	background-color: #fff !important;
	border: 1px solid #ECECEC !important;
}

.wpb_tabs .wpb_tabs_nav li,
.wpb_tour .wpb_tabs_nav li {
	background-color:#fff !important;
	position: relative !important;
	top: 1px !important;
	border: 1px solid #F8F8F8;
	border-bottom: none;
}
.wpb_tabs .wpb_tabs_nav li.ui-tabs-active,
.wpb_tour .wpb_tabs_nav li.ui-tabs-active{
	background-color: #FdFdFd !important;
	border: 1px solid #f1f1f1 !important;
}
.wpb_tabs .wpb_tabs_nav li.ui-tabs-active{
	border-bottom:none !important;
}
.wpb_tour .wpb_tabs_nav li.ui-tabs-active{
	border-right:none !important;
	position:relative;
	left:1px;
	z-index:2;
}
.wpb_tab.ui-tabs-panel{
	background-color: #FdFdFd !important;
	border: 1px solid #F1F1F1 !important;
	padding: 25px !important;
	position:relative;
	top:1px;
}
.wpb_tour_next_prev_nav{
	display:none;
}
.wpb_accordion_content,
.wpb_content_element.wpb_tabs .wpb_tour_tabs_wrapper .wpb_tab{
	background-color: #FdFdFd !important;
	border: 1px solid #F1F1F1 !important;
	border-top: none !important;
}
.wpb_accordion_content{
	font-size:1em;
}

[aria-expanded="true"]{
	opacity:1 !important;
	margin-bottom: 5px;
}

.wpb_toggle{
	background-color:#fff !important;
	padding: 10px 10px 10px 30px !important;
	background-position: 10px center !important;
	font-weight: 500;
	font-size: 15px;
	border: 1px solid #F2F2F2;
	margin: 0 !important;
	margin-bottom: 5px !important;
}
.wpb_toggle_content{
	background-color:#fdfdfd !important;
	margin:0 !important;
	padding:0px !important;
	border: 1px solid #f1f1f1;
	margin-bottom:10px !important;
	margin-top:-10px !important;
	border-top:none !important;
}
.wpb_toggle_content p{
	padding: 0 15px;
}
.wpb_toggle_content p:first-child{
	padding-top: 15px;
}
.wpb_toggle_content p:last-child{
	padding-bottom: 15px;
}
.wpb_tabs_nav li{
	transition:none !important;
}

/* sharing
**************************************************/
.googleplusone{
	display: inline;
	position: relative;
	top: 6px;
}
#fb-root{
	display:none;
}
.twittershare{
	display: inline;
	position: relative;
	top: 6px;
}
.post-socials {
	float: right;
	margin: 0;
	padding: 0;
	list-style: none;
}
.post-socials li {
	display: inline-block;
	margin-right: 5px;
}
.post-socials li a {
	display: block;
	background: #fdfdfd;
	color: #494949;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	box-shadow: 0px 0px 1px 0px #494949;
	-webkit-transition: all .25s ease;
	-moz-transition: all .25s ease;
	-ms-transition: all .25s ease;
	-o-transition: all .25s ease;
	transition: all .25s ease;
	font-size: 13px;
}
.post-socials li a:hover {
	background: #ddd;
}
@media screen and (max-width: 770px) {
	.post-socials {
		float: none;
		margin-top:15px;
	}
}


/* fix for mobile view of pages */
@media screen and (max-width: 770px) {
	 #blog-side{
	 	width:100%;
	 	right:-100%;
	 }
	 #blog-content{
	 	width:100% !important;
	 }
	 .fac-page{
	 	width:100% !important;
	 	left:0 !important;
	}
	 .archive-header {
		padding: 0px;
		text-align: center;
	}
	.archive-header h3{
		padding: 35px 0;
		margin: 0;
	}
	.headercontent .title{
		font-size: 20px;
	}
	#hideshow {
		right: 0;
	}
	#hideshow span{
		display: inline;
	}
}

.img-responsive, .img-responsive img{ max-width:100%; height: auto; }
.wpb_single_image .vc_single_image-wrapper{max-width:100%; height: auto;}
.single-post img{
	max-width: 100%;
	height:auto;
}


/* pagination */
.fac-pagination{
	background: #fefefe;
	padding: 20px;
	-webkit-box-shadow: 0px 3px 3px -4px #ccc;
	box-shadow: 0px 3px 3px -4px #ccc;
	margin-bottom: 20px;
	border-left: 5px solid #03cc85;
}

.fac-pagination a,
.fac-pagination span{
	margin:0 3px;
	padding:0 3px;
}
.fac-pagination span:first-child{
	margin-left:0;
	padding-left:0;
}




.wpb_flexslider .slides img{
    width: 100%;
    height: auto !important;
}


/* remove padding top on wrappers of vc  */
.vc_col-has-fill>.vc_column-inner, 
.vc_row-has-fill+.vc_row-full-width+.vc_row>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill+.vc_row>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill+.vc_vc_row>.vc_row>.vc_vc_column>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill+.vc_vc_row_inner>.vc_row>.vc_vc_column_inner>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill>.vc_row>.vc_vc_column>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill>.vc_vc_column_inner>.vc_column_container>.vc_column-inner {
        padding-top: 0 !important;
}

/*handles screen reader text properly*/
.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}
.screen-reader-text:focus {
    clip: auto !important;
    display: block;
    height: auto;
    left: 5px;
    top: 5px;
    width: auto;
    z-index: 100000; /* Above WP toolbar. */
}

/*side footer widget*/
#side-footer-widget{
	margin-bottom: 15px;
}
#side-footer-widget h5.title{
	color: #ccc;
}

/*Hide that line on small devices*/
@media screen and (max-width: 992px){
    .pagecontents.has-sidebar:after {
        width: 0px;
    }
}


/* Publication single page*/
.pubheader{
	height: 280px;
	background-color: #ccc;
	background-image: url('../img/publication-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	background-position: center;
	padding-bottom: 30px;
}
.pubheader:after{
	display: none;
}
.pubheader:before{
	content: '';
	position: absolute;
	top:0;
	left:0;
	width:100%;
	height: 100%;
	background-color: rgba(241, 241, 241, 0.9);
}
.pubheader .section-container,
.pubheader .header-wrapper{
	position: relative;
	height: 100%;
}
.pubheader .title{
	position: absolute;
	bottom: 0;
	left:0;
	padding-left: 190px;
	border-bottom: none;
	margin: 0;
	font-weight: bold;
	font-weight: 700;
	font-size: 24px;
	padding-right:10%;
	line-height: 1.2;
}
.no_thumbnail .pubheader .title{
	padding-left: 0px;
}
.pub-single-info{
	position: relative;
}
.pub-single-info .description{
	padding-left: 190px;
}
.no_thumbnail .pub-single-info .description{
	padding-left: 0px;
}
.pub-single-info .attachment{
	position: absolute;
	bottom:0;
	left:0;
	width: 160px;

}
.pub-single-info .attachment .pub-thumb{
	border-radius: 4px;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
	overflow: hidden;
	max-height: 210px;
}
.pub-single-info .attachment .pub-thumb img{
	width: 100%;
	height: auto;
}

   
.pub-single-info ul.cats{
	list-style: none;
	padding: 0;
	margin-bottom: 10px;
}
.pub-single-info ul.cats li{
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 15px;
}

.pub-single-info ul.cats li a{
	padding: 7px 25px;
	background-color: #fff;
	color:#444;
	font-size: 12px;
	border-radius: 4px;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
	-webkit-transition: all .25s ease;
	    -moz-transition: all .25s ease;
	     -ms-transition: all .25s ease;
	      -o-transition: all .25s ease;
	         transition: all .25s ease; 
}

.pub-single-info ul.cats li a:hover,
.pub-single-info ul.cats li a:focus{
	background-color: #333;
	color:#fff;
}

.pub-single-info .link-buttons{
	margin-top: 20px;
}
.pub-single-info .link-buttons >a{
	padding: 5px 10px;
	background-color: #03cc85;
	color: #fff;
	display: inline-block;
    margin-bottom: 5px;
    border-radius: 4px;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
	font-size: 12px;
}
.pub-single-info .link-buttons >a:hover,
.pub-single-info .link-buttons >a:focus{
	background-color: #333;
	color: #fff;
}
.pub-single-info .link-buttons >a span{
	margin-left: 3px;
}

.pub-single-info .link-buttons .download-btn{
	float: left;
}
.pub-single-info .link-buttons .external-btn{
	display: block;
	margin-left: 38px;
}
.pub-single-info .link-buttons .external-btn.inline{
	display: inline-block;
	margin-left: 5px;
}
.pub-single-info .link-buttons .external-btn.full{
	margin-left: 0;
}


.pub-single-info .authors,
.pub-single-info .citation,
.pub-single-info .pubyear{
	font-size: 14px;
}

.pub-single-info .authors,
.pub-single-info .citation{
	margin-bottom: 5px;
}

.pubcontents>.section{
	padding-top: 25px;
}
.pubcontents .pub-content{
	margin-top:80px;
}


.fac-publications .pubmain,
.pitems.no-toggle .pubmain{
	padding-bottom: 25px;
	cursor: initial;
}
.fac-publications .pitems .pubmain .pubcontents,
.pitems.no-toggle .pubmain .pubcontents{
	margin-bottom: 0;
}

.middle-cols{
	display: inline-block;
	vertical-align: middle;
	width: 50%;
}
.middle-cols.primary-col{
	width:30%;
}
.middle-cols.secondary-col{
	width:68%;
}



    .panel-heading {
  padding: 0;
	border:0;
}
.panel-title>a, .panel-title>a:active{
	display:block;
	padding:15px;
  // color:#555;
 //  font-size:16px;
 //  font-weight:bold;
	// text-transform:uppercase;
	// letter-spacing:1px;
  // word-spacing:3px;
	text-decoration:none;
}
.panel-heading  a:before {
   font-family: 'FontAwesome';
   content: "\f107";
   float: right;
   transition: all 0.5s;
}
.panel-heading.active a:before {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	transform: rotate(180deg);
} 

.panel-default>.panel-heading{
	background: #fff;
}

.panel-body p{
	font-size: 16px;	
}

[aria-expanded=true]{
	margin-bottom: 0;	
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	.pubheader .title{
		font-size: 20px;
		padding-right: 0;
	}
	.pubheader{
		height: 200px;
	}

	#main{
		width: 100%;
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	.pubheader .title{
		padding-left: 0;
		position: static;
		padding-top: 25px;
		font-size: 20px;
		padding-right: 0;
	}
	.pubheader{
		height: auto;
	}
	.pub-single-info .attachment{
		position: static;
	}
	.pub-single-info .description{
		padding-left: 0;
		margin-top: 30px;
	}
	.pub-single-info ul.cats li a{
		padding: 7px 10px; 
	}
	.pubcontents .pub-content{
		margin-top: 20px;
	}
	.middle-cols{
		display: block;
		width: 100%;
	}
	.middle-cols.primary-col{
		width:100%;
	}
	.middle-cols.secondary-col{
		width:100%;
	}
	.pitems .pubmain .pubcontents{
        padding-right:0px;
    }

.fac-page #inside>.row {
    padding: 70px 10px;
    position: relative;
    margin: 0;
}


}



/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
	
}

p{
	font-size: 18px;
	line-height: 1.5em;
}


.widget ul li {
	line-height: 1.5em;
	font-weight: 	300;
}

.widget ul li {
    border-left-color: #03cc85;
}


.widgettitle{
	border-bottom: none;
	padding-bottom: 0;
}


.sideIn{
	left: 250px !important;
}

#sidebar{
		-webkit-transition-duration: .3s;
	   -moz-transition-duration: .3s;
	    -ms-transition-duration: .3s;
	     -o-transition-duration: .3s;
	        transition-duration: .3s;

}


  @media only screen and (max-width : 992px) {

.img-responsive {
	text-align: center;
}

.img-responsive img{
	max-width: 280px;
	width: 100%;
}

// body{
// 	-ms-overflow-x: 	hidden;
// 	    overflow-x: 	hidden;
// 	        max-width: 	100px;
// }


.sideIn{
	left: 0 !important;
}

#wrapper,
#sidebar{
	max-width: 	100%;
	overflow: hidden;	
}

#sidebar{
	top: 0;	
	max-height: 	100vh;
	z-index: 	999;
	left: -100%;	
}





    }


.oh{
	overflow: hidden;	
}


  @media only screen and (min-width : 768px) {
.fac-title{
	padding-top: 0;
}

    }




      @media only screen and (min-width : 767px) {
    
    .label{
    	position: relative;
    	top: -2px;
    	margin-left: 10px;
    }


    .tb{
    	display: table;
    }

    .tb-col{
    	display: table-cell;
    	vertical-align: middle;
    	float: none;
    }



        }